<template>
  <div class="dids" v-if="currentUser && currentUser.fullAccessNumbers">
    <div class="d-flex bg-white p-3 align--center datatable-controls">
      <items-per-page
        :options="perPageOptions"
        :label="$t('shared.table.per-page')"
        :defaultPerPage="10"
        @items-per-page="onPerPageChange"
      />
    </div>

    <data-table
      ref="didsTable"
      :data="dids"
      :fields="translatedTableFields"
      :isLoading="didsLoading"
      :pagination="pagination"
    >
      <template slot="status" slot-scope="props">
        <DidStatus :rowData="props.rowData" />
      </template>
      <template slot="on_call" slot-scope="props">
        <DidOnCall :rowData="props.rowData" />
      </template>
    </data-table>
  </div>
</template>

<script>
import Vue from 'vue'
import DataTable from '../../../elements/DataTable.vue'
import ItemsPerPage from '../../../vuestic-theme/vuestic-components/vuestic-datatable/datatable-components/ItemsPerPage'
import DidService from '../../../services/did.service'
import { mapGetters } from 'vuex'

let interval

export default {
  components: {
    DataTable,
    ItemsPerPage
  },
  data() {
    return {
      fields: [
        {
          name: 'idx',
          title: 'dids.table.index',
          width: '10%'
        },
        {
          name: 'phoneNumber',
          title: 'dids.table.phone-number'
        }
      ],
      dids: [],
      didsLoading: false,
      pagination: {
        perPage: 10
      },
      perPageOptions: [
        {
          value: 10
        },
        {
          value: 25
        },
        {
          value: 100
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    translatedTableFields() {
      return this.fields.map(field => ({
        ...field,
        title: this.$t(field.title)
      }))
    }
  },
  methods: {
    async getDids(silent) {
      try {
        if (!silent) this.didsLoading = true
        const data = await DidService.fetchAllDids()
        this.dids = data
      } catch (error) {
      } finally {
        if (!silent) this.didsLoading = false
      }
    },
    onPerPageChange(val) {
      this.pagination.perPage = val
      Vue.nextTick(() => this.$refs.didsTable.refresh())
    }
  },
  mounted() {
    this.getDids()

    interval = setInterval(() => {
      this.getDids(true)
    }, 60000)
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(interval)
    next()
  }
}
</script>
<style lang=""></style>
