import { api } from '../axios'

const fetchAllDids = async () => {
  const res = await api.get(`jera/dids`)
  return res.data.map((did, idx) => ({
    idx: idx + 1,
    phoneNumber: did
  }))
}

const DidService = {
  fetchAllDids
}

export default DidService
